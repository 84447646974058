<template>
  <div class="content">
    <div class="bannerView">
      <img class="img" :src="banner" alt="" />
      <div class="bannerBlock">
        <div class="logoView">
          <img :src="block.logo" alt="" />
        </div>
        <div class="txtView">
          <h4>{{ block.title }}</h4>
          <div class="link">{{ block.link }}</div>
          <p>{{ block.content }}</p>
        </div>
      </div>
    </div>
    <div class="main html-view" v-html="html"></div>
    <el-button type="primary" class="back_list" @click="$router.back(-1)" round
      >返回列表</el-button
    >
    <div class="picView">
      <img class="pic" :src="pic" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "UseCasesDetails",
  data() {
    return {
      id: this.$route.query.id,

      banner: "",
      block: {},
      html: "",
      pic: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http.use_cases_details({ id: this.id }).then((res) => {
        if (res.code == 200) {
          this.banner = res.data.banner;
          this.block = {
            logo: res.data.logo,
            title: res.data.title,
            link: res.data.link,
            content: res.data.description,
          };
          this.html = res.data.content;
          this.pic = res.data.d_banner;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.content {
  .bannerView {
    position: relative;
    .img {
      display: block;
      width: 100%;
    }
    .bannerBlock {
      height: 250px;
      width: 1170px;
      background: #fff;
      box-sizing: border-box;
      padding: 40px 60px;
      position: absolute;
      left: 50%;
      bottom: -125px;
      transform: translateX(-50%);
      box-shadow: 0px 10px 20px #eee;
      display: flex;
      justify-content: space-between;
      @include respond-to(max1366) {
        width: 90%;
      }
      @include respond-to(lg) {
        padding: 20px 30px;
        height: auto;
      }
      @include respond-to(sm) {
        position: static;
        flex-direction: column;
        transform: translateX(0);
        width: 100%;
        box-shadow: 0px 0px 0px #eee;
        padding: 15px;
      }
      .logoView {
        width: 250px;
        @include respond-to(sm) {
          margin: 0 auto;
        }
        img {
          display: block;
          width: 100%;
        }
      }
      .txtView {
        width: calc(100% - 250px - 70px);
        @include respond-to(sm) {
          width: 100%;
          padding-top: 20px;
        }
        h4 {
          font-weight: normal;
          font-size: 24px;
          color: #1a1a1a;
          @include respond-to(sm) {
            font-size: 18px;
          }
        }
        .link {
          font-size: 16px;
          color: #0075c1;
        }
        p {
          font-size: 16px;
          line-height: 1.8;
          color: #333;
          margin-top: 15px;
          @include respond-to(sm) {
            font-size: 14px;
          }
        }
      }
    }
  }
  .main {
    width: 1000px;
    margin: 0 auto;
    padding: 220px 0 70px;
    @include respond-to(lg) {
      width: 90%;
      padding: 110px 0 40px;
    }
    @include respond-to(sm) {
      width: 100%;
      padding: 15px;
    }
    /deep/ img {
      max-width: 100%;
    }
  }
  .back_list {
    display: block;
    margin: 0 auto;
    width: 128px;
    background-color: #000;
    border-color: #000;
    color: #fff;
  }
  .picView {
    padding-bottom: 30px;
    @include respond-to(sm) {
      padding-bottom: 20px;
    }
    .pic {
      display: block;
      width: 100%;
      margin: 80px 0 0;
      @include respond-to(sm) {
        margin: 20px 0 0;
      }
    }
  }
}
</style>